import React from 'react'
import { OnGoingEventList } from './OnGoingEventList'
import { UpComingEventList } from './UpComingEventList'

export const EventCardList = ({setShowComponent}) => {
  return (
    <div className='hidescroll' style={{overflow: "auto", height: "68vh"}}>
        <OnGoingEventList setShowComponent={setShowComponent}/>
        <UpComingEventList setShowComponent={setShowComponent}/>
    </div>
  )
}
