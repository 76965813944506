import { ChatHead } from "../../components/user/userChatHistory/ChatHead"
import { Footer } from "../../components/user/Footer"
import { axiosAuthInstance } from "../../axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import NavBar from "../../components/user/NavBar"

export const UserChatHistory = () => {

    const userId = localStorage.getItem("userID");
    const [historyChat, setHistoryChat] = useState();
    const [chats, setChats] = useState(null);
    const [organizationId, setOrganizationId] = useState("");
    const [company, setCompany] = useState();

    const navigate = useNavigate();

    const getHistoryChats = () => {
        axiosAuthInstance({
            method: "post",
            url: "api/message/messengerHistoryUsers",
            data: {
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/");
                }
                if (res.data.status == "success") {
                 
                    setHistoryChat(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    const getMessages = () => {
        axiosAuthInstance({
            method: "post",
            url: "api/message/getMessage",
            data: {
                userId: userId,
                organizationId: organizationId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/");
                }
                if (res.data.status == "success") {
                    setChats(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    const getOrgDetails = () => {
        axiosAuthInstance({
            method: "post",
            url: "api/user/organizationDetails",
            data: {
                organizationId: organizationId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/");
                }
                if (res.data.status == "success") {
                    setCompany(res.data.message);
                }
            })
            .catch((err) => console.log(err));
    }

    

    useEffect(() => {
        getHistoryChats();
    }, [])

    useEffect(() => {
        if (organizationId !== "") {
            getMessages();
            getOrgDetails();
        }
    }, [organizationId])

    return (
        <>
            <NavBar />
            <div class="container-fluid mb-5">
                <div className="text-center my-3" style={{ fontSize: "32px", fontWeight: "600", color: "#235183" }}>
                    CHAT HISTORY
                </div>

                <div className="row mx-1 mx-md-5 shadow" style={{ height: "80vh" }}>
                    <div className="col-4 px-0 mx-0" style={{ border: "1px solid rgb(221, 221, 221)" }}>
                        <div className="py-4 text-center border" style={{ fontSize: "20px" }}>
                            ORGANIZATIONS
                        </div>

                        <div className="hidescroll" style={{ overflow: "auto", height: "65vh" }}>
                            {historyChat?.map((item) => {
                                return (
                                    <ChatHead message={item} setOrganizationId={setOrganizationId} />
                                )
                            })}
                        </div>
                    </div>
                    <div class="col-8 m-0 p-0" style={{ border: "1px solid rgb(221, 221, 221)", position: "relative" }}>

                        {
                            organizationId != "" ?
                                <div class="m-0 p-0" style={{ backgroundColor: "#235183d6" }}>
                                    <div class="d-flex justify-content-between p-2">
                                        <div class="mx-1 d-flex">
                                            <img src={chats?.organizationId?.coverImage} style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                                                class="img-fluid shadow" alt="" />
                                            <div class="mt-3 mx-3 text-center">
                                                <span style={{ fontSize: "24px", color: "white" }}>{chats?.organizationId?.name}</span><br />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <button class="p-3 d-none d-md-inline"
                                                style={{ backgroundColor: "white", border: "none", borderRadius: "1.5rem", fontSize: "12px" }} onClick={() => {navigate("/companyprofile", {state: {company: company}})}}>View
                                                Profile</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }



                        <div class="mt-4 mx-3 hidescroll" style={{overflow : 'auto', height: "64vh"}}>
                            {chats?.message?.map((item) => {
                                return (
                                    item.sender == "user" ?
                                        <div class="d-flex justify-content-end">
                                            <div class="my-1" style={{ color: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px", border: "1px solid rgb(221, 221, 221)" }}>
                                                {item.message}
                                            </div>
                                        </div>
                                        :
                                        <div class="d-flex justify-content-start">
                                            <div class="text-white my-1" style={{ backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px" }}>
                                                {item.message}
                                            </div>
                                        </div>
                                )
                            })}

                            
                        </div>
                    </div>
                </div>

                {/* <div className="row mx-1 mx-md-5 shadow" style={{height: "80vh"}}>
                    <div className="row mx-auto p-0">
                        <div className="col-4 py-4 text-center border" style={{fontSize: "20px"}}>
                            ORGANIZATIONS
                        </div>
                        <div class="col-8 m-0 p-0" style={{backgroundColor: "#235183d6"}}>
                            <div class="d-flex justify-content-between p-2">
                                <div class="mx-1 d-flex">
                                    <img src={CompanyProfile} style={{height: "65px", width: "65px", borderRadius: "50%"}}
                                        class="img-fluid shadow" alt=""/>
                                    <div class="mt-3 mx-3 text-center">
                                        <span style={{fontSize: "24px", color: "white"}}>ORGANIZATIONS NAME</span><br/>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <button class="p-3 d-none d-md-inline"
                                        style={{backgroundColor: "white", border: "none", borderRadius: "1.5rem", fontSize: "12px"}}>View
                                        Profile</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-auto p-0" style={{overflow: "hidden"}}>
                        <div className="col-4 hidescroll" style={{overflow: "auto",border: "1px 1px 1px 0px solid grey" , height: "66vh"}}>
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                            <ChatHead />
                        </div>
                        <div class="col-8 pt-4 mx-0 border border-top-0 border-bottom-0 border-right-0">
                                <div class="d-flex justify-content-start">
                                    <div class="text-white my-1" style={{backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px"}}>
                                        Hey There!
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start">
                                    <div class="text-white my-1" style={{backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px"}}>
                                        How you doing?
                                    </div>
                                </div>
            
                                <div class="d-flex justify-content-end">
                                    <div class="my-1" style={{color: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px", border: "1px solid rgb(221, 221, 221)"}}>
                                        I'm good what about you?
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start">
                                    <div class="text-white my-1" style={{backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px"}}>
                                        I've been reading books of old...
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}