import BorderExample from "./Bootstap/Spinner"

export const Loader = ({ }) => {
    return (
        <div
            style={{
                widht: "100vw",
                height: "35vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <BorderExample />
        </div>
    )
}
