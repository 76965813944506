import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../../axios";
import { filterEvents } from "../../../functions";
import { Loader } from "../../Loader";
import { CompanyCard } from "./CompanyCard";

export const CompanyList = ({search,companyList,setCompanyList, limit}) => {
    const eventsPerPage = 8
    let navigate = useNavigate();
    const [isloading, setloading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const getCompanies = () => {
        axiosAuthInstance({
            method: "post",
            url: "api/user/listOrganizations",
            data: {
                limit: limit,
                currentPage,
            }
        }).then(response => {
            if (response.data.statusCode == 403) {
                localStorage.clear();
                navigate("/");
            }
            setCompanies(response.data.message);
            setCompanyList(response.data.message);
            setTotalCompanies(response.data.companyCount)
            setloading(false);
        }).catch(err => console.log(err));
    }
    useEffect(()=>{
        setCompanyList(filterEvents(companies, search))
    }, [search])

    useEffect(() => {
        getCompanies()
    }, [currentPage]);
    
    const paginationArray = []
    let startIndex = 0
    if(Math.ceil(totalCompanies / eventsPerPage) == 2 || Math.ceil(totalCompanies / eventsPerPage) == 1){}
    else if(Math.ceil(totalCompanies / eventsPerPage) == 3){
        paginationArray.push(1);
    }
    else if(currentPage == Math.ceil(totalCompanies / eventsPerPage) - 1){
        paginationArray.push(Math.ceil(totalCompanies / eventsPerPage) -3);
        paginationArray.push(Math.ceil(totalCompanies / eventsPerPage) -2);
    }else{
        for (let i = currentPage-1; i < Math.ceil(totalCompanies / eventsPerPage); i++) {
        if(startIndex >= 2) break; 
        if(i < 1) continue;
        paginationArray.push(i);
        startIndex++
        }
    }

    return (
        <div className="container my-4">
            <div>
                <h1 className="text-center" style={{ color: "#235183" }}>ORGANIZATIONS</h1>
            </div>
            <div className="mt-2">
                <div className="row">
                    {isloading ? <Loader /> : companyList.map((item, index) => {
                        return (
                            <CompanyCard key={index} company={item}/>
                        )
                    })}
                </div> 
            </div>
            {limit ? <div className="mt-4" style={{ display: 'flex', justifyContent: "flex-end" }}>
                <button className='btn' style={{ backgroundColor: "#235183", color: "white" }} onClick={() => navigate("/allCompanies")}>See All</button>
            </div> : null}

            {limit ? null : <div className="mt-3">
                <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className={currentPage < 1 ? "page-item disabled" : "page-item"}>
                    <button className="page-link" to="" onClick={()=>{
                        setCurrentPage(prevStat => {
                        if(prevStat>=1){
                            return prevStat-1
                        }
                        return prevStat
                        })
                    }}>
                        Previous
                    </button>
                    </li>
                    <li className="page-item">
                    <button className="page-link" to="" onClick={()=> setCurrentPage(0)} style={currentPage == 0 ? {background: '#235183', color:'white'} : {}}>
                        1
                    </button>
                    </li>
                    {
                    Math.ceil(totalCompanies / eventsPerPage) > 4 && currentPage >= 3 ? <li className="page-item">
                        <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat - 2)}>
                        ...
                        </button>
                    </li>: null
                    }
                    {
                    paginationArray.map((item, index) => {
                        return <li key={index} className="page-item">
                        <button className="page-link" to="" onClick={()=> setCurrentPage(item)} style={currentPage == item ? {background: '#235183', color:'white'} : {}}>
                            {item + 1}
                        </button>
                        </li>
                    })
                    }
                    {
                    Math.ceil(totalCompanies / eventsPerPage) > 4 && currentPage < Math.ceil(totalCompanies / eventsPerPage) - 2 ? <li className="page-item">
                        <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat + 2)}>
                        ...
                        </button>
                    </li> : null
                    }
                    {
                    Math.ceil(totalCompanies / eventsPerPage) == 1 ? null : <li className="page-item">
                        <button className="page-link" to="" onClick={()=> setCurrentPage(Math.ceil(totalCompanies / eventsPerPage) - 1)} style={currentPage == Math.ceil(totalCompanies / eventsPerPage) - 1 ? {background: '#235183', color:'white'} : {}}>
                        {Math.ceil(totalCompanies / eventsPerPage)}
                        </button>
                    </li> 
                    }
                    <li className={currentPage >= Math.ceil(totalCompanies / eventsPerPage) - 1 ? "page-item disabled" : "page-item"}>
                    <button className="page-link" to="" onClick={() => {
                        setCurrentPage(prevStat => {
                            return prevStat+1
                        })
                    }}>
                        Next
                    </button>
                    </li>
                </ul>
                </nav>
            </div>}

        </div>
    )
}