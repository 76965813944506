import React, { useContext, useState } from "react";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/contact.jpg";
import { axiosAuthInstace, axiosInstance } from "../../axios";
import axios from "axios";
// import { AuthContext } from "../../context/AuthProvider";
import { validateEmail } from "../../functions";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { UserContext } from "../../context/AuthProvider";
import { Popup } from "../../components/Popup";

export const Login = () => {
  const { user, setUser } = useContext(UserContext);

  const [pop, setPop] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  let navigate = useNavigate();

  const HandleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setBody("Invalid Email");
      setTitle("Email Failed");
      setPop(true);
    } else {
      setIsDisabled(true);
      axiosInstance({
        method: "post",
        url: "api/user/signIn",
        data: {
          email: email,
          password: password,
        },
      })
        .then((res) => {
          setIsDisabled(false);
          if (res.data.status === "success") {
            localStorage.setItem("userToken", res.data.token);
            localStorage.setItem("email", res.data.Data.email);
            localStorage.setItem("userID", res.data.Data._id);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userRole", "1");
            setUser((preState) => {
              return {
                ...preState,
                email: res.data.Data.email,
                token: res.data.token,
                userId: res.data.Data._id,
                isLoggedIn: true,
                role: "user",
              };
            });
            navigate("/candidatehome");
          } else if (res.data.status === "failed") {
            setTitle("Login Failed");
            setBody(res.data.message);
            setPop(true)
            setIsDisabled(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Popup show={pop} setShow={setPop} title={title} body={body} />
      <div className="grid-container">
        <div className="item">
          <div style={{ marginTop: "15%", textAlign: "center" }}>
            <h1 style={{ fontWeight: "600", color: "#235183" }}>USER LOGIN</h1>
            <div style={{ margin: "3%" }}>
              <label style={{ color: "#8f8f8f" }}>EMAIL</label>
              <br />
              <input
                style={{
                  border: "none",
                  borderBottom: "1px solid #8f8f8f",
                  padding: "1%",
                  width: "45%",
                }}
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div style={{ margin: "3%" }}>
              <label style={{ color: "#8f8f8f" }}>PASSWORD</label>
              <br />
              <div
                style={{
                  borderBottom: "1px solid #8f8f8f",
                  width: "46%",
                  marginLeft: "27%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{ border: "none", padding: "1%", width: "100%" }}
                  type={passwordShow ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <div>
                  {passwordShow ? (
                    <AiFillEyeInvisible
                      size={20}
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  ) : (
                    <AiFillEye
                      size={20}
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginTop: "2%", color: "#8f8f8f" }}>
              Forget Password?
            </div>
            <div style={{ marginTop: "3%" }}>
              <button
                disabled={isDisabled}
                onClick={(event) => {
                  HandleSubmit(event);
                }}
                className="signIn-fill"
                style={{
                  width: "45%",
                  padding: "1.5%",
                  backgroundColor: "#235183",
                  color: "white",
                  borderRadius: "2rem",
                  border: "none",
                }}
              >
                SIGN IN
              </button>
            </div>
          </div>
        </div>
        <div className="item">
          <div
            className="login-cover"
            style={{
              backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.4826681014202556) 0%, rgba(0, 0, 0, 0.7878781854538691) 100%, rgba(0, 0, 0, 1) 100%),url(${banner})`,
              height: "100vh",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div>
              <h1 style={{ color: "white", paddingTop: "15%" }}>New Here?</h1>
              <p style={{ color: "white", fontSize: "18px", marginTop: "2%" }}>
                Sign Up
              </p>
              <div style={{ marginTop: "80%" }}>
                <button
                  disabled={isDisabled}
                  onClick={() => navigate("/signup")}
                  className="signUp-trans"
                  style={{
                    width: "70%",
                    padding: "2%",
                    border: "2px solid white",
                    color: "white",
                    backgroundColor: "transparent",
                    borderRadius: "2rem",
                  }}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
