import { UpComingEventList } from '../../components/user/candidateHome/UpComingEventList';
import { Footer } from '../../components/user/Footer'
import NavBar from '../../components/user/NavBar';

export const AllUpcomingEventsPage = () => {
  return (
    <>
      <NavBar />
      <div style={{minHeight: '72vh'}}>
        <UpComingEventList />
      </div>
      <Footer />
    </>
  )
}
