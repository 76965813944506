import React from "react";
import { Link } from "react-router-dom";

export const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link style = {{textDecoration: "none", fontWeight: "400", color: "#235183"}}
            to="#"
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};
