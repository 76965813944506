import React from "react";
import { UserCard } from "./UserCard";

export const UserList = ({users}) => {
  return (
    <div className="container my-5">
      <div>
        <h1 className="text-center" style={{ color: "#235183" }}>
          ORGANIZATIONS
        </h1>
      </div>
      <div className="mt-4">
        <div className="row">
          {users &&
            users?.map((item, index) => {
              return <UserCard key={index} details={item} />;
            })}
        </div>
      </div>
    </div>
  );
};
