import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../../axios";

export const UserMessagesRight = ({ organizationId, getActiveChats, getUserChat, chat, setChat }) => {  
    let navigate = useNavigate();

    const [text, setText] = useState("");

    const sendMessage = (messageId) => {
        axiosAuthInstance({
            method: "post",
            url: "api/message/sendMessageUpdated",
            data: {
                messageId: messageId,
                eventId: "638e7ab1f0565a29af092400",
                sender: "user",
                message: text
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/");
                }
                if (res.data.status == "success") {
                    getUserChat();
                    getActiveChats();
                    setText("");
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (organizationId !== "") {
            getUserChat();
        }
    }, [organizationId])

    return (
        <div className="col-8 m-0 p-0" style={{ border: "1px solid rgb(221, 221, 221)", position: "relative" }}>

            {organizationId !== "" ?
                <div className="m-0 p-0" style={{ backgroundColor: "#235183d6" }}>
                    <div className="d-flex justify-content-between p-2">
                        <div className="mx-1 d-flex">
                            <img src={chat?.organizationId?.coverImage} style={{ height: "55px", width: "55px", borderRadius: "50%" }}
                                className="img-fluid shadow" alt="" />
                            <div className="mt-2 mx-3 text-center">
                                <span style={{ fontSize: "24px", color: "white" }}>{chat?.organizationId?.name}</span><br />
                            </div>
                        </div>
                        <div className="mt-2">
                            <button className="p-2"
                                onClick={() => {navigate("/companyprofile", { state: {orgId: organizationId}})}}
                                style={{ backgroundColor: "white", border: "none", borderRadius: "1.5rem", fontSize: "12px" }}>View
                                Profile</button>
                        </div>
                    </div>
                </div>
                :
                <>
                </>}



            <div className="mt-4 mx-3 hidescroll" style={{overflow : 'auto', height: "58vh"}}>
                {
                    chat?.message?.map((item) => {
                  
                        return (
                            item.sender == "user" ?
                                <div className="d-flex justify-content-end">
                                    <div className="my-1"
                                        style={{ color: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px", border: "1px solid rgb(221, 221, 221)" }}>
                                        {item?.message}
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-start">
                                    <div className="text-white my-1"
                                        style={{ backgroundColor: "#466D97", width: "max-content", padding: ".8rem 1rem .8rem 1rem", borderRadius: "2rem", fontSize: "14px" }}>
                                        {item?.message}
                                    </div>
                                </div>
                        )
                    })
                }
            </div>

            {
                organizationId !== "" ?
                    <div className="w-100" style={{ position: "absolute", bottom: "0", left: "0" }}>
                        <hr className="mb-2 pb-0" />
                        <div className=" d-flex mb-2 mx-2">
                            <input type="text" style={{ width: "100%" }} className="form-control" value={text} onChange={(e) => setText(e.target.value)} />
                            <button className="mx-2"
                                style={{ padding: "10px 20px 10px 20px", backgroundColor: "#235183d6", color: "white", border: "none", borderRadius: ".5rem" }} onClick={() => { sendMessage(chat?._id) }}>Send</button>
                        </div>

                    </div>
                    :
                    <></>
            }



        </div>
    )
}