import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import {
    MdOutlineSpaceDashboard,
    MdOutlineMiscellaneousServices,
} from "react-icons/md";
import { useState } from "react";
import { Booth } from "../../components/organization/booth/Booth";
import "../../assets/css/dashboard.css";
import { Popup } from "../../components/Popup";
import { OrganizationDetails } from "../../components/organization/profile/OrganizationDetails";
import { FaRegUser } from "react-icons/fa";
import { EditProfile } from "../../components/organization/profile/EditProfile";
import { OrganizationUserBooth } from "../../components/organization/organizationusers/OrganizationUserBooth";

export const OrganizationUserDashboard = () => {
    const [showComponent, setShowComponent] = useState("booth");
    const [pop, setPop] = useState(false);
    const [search, setSearch] = useState(""); //not implemented
    let Navigate = useNavigate()

    return (
        <>
            <div className="parentContainer">
                <div>
                    <div>
                        <h2
                            style={{
                                textAlign: "center",
                                marginTop: "15%",
                                fontSize: "28px",
                                textTransform: "uppercase",
                                color: "white",
                                fontWeight: "700",
                            }}
                        >
                            Virtual Career Fair
                        </h2>
                        <ul style={{ padding: 0, margin: "20% 0", textAlign: "left" }}>

                            <li
                                className={showComponent === "booth" ? "activate" : ""}
                                onClick={() => {
                                    setShowComponent("booth");
                                }}
                            >
                                <Link>
                                    <MdOutlineMiscellaneousServices size={30} />
                                    <span>BOOTH</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/organizationUserlogin" onClick={() => {
                                    localStorage.clear()
                                    Navigate('/organizationUserlogin')
                                }}>
                                    <FiLogOut size={30} />
                                    <span>LOGOUT</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "98vh",
                            width: "80%",
                            borderRadius: "25px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                height: "13vh",
                                alignItems: "center",
                            }}
                        >
                            <h2 style={{ paddingLeft: "30px" }}>
                                Welcome{" "}
                                <span style={{ color: "#235183", fontWeight: "600" }}>{localStorage.getItem('name')}</span>
                            </h2>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    paddingRight: "50px",
                                }}
                            >
                                <div
                                    className="border shadow"
                                    style={{ borderRadius: "10px", padding: "0 8px" }}
                                >
                                    <button
                                        style={{
                                            height: "40px",
                                            padding: "0 10px",
                                            border: "none",
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <AiOutlineSearch />
                                    </button>
                                    <input
                                        style={{
                                            height: "40px",
                                            padding: "0 10px",
                                            border: "none",
                                            outline: "none",
                                        }}
                                        placeholder="Search"
                                    />
                                </div>
                                <div style={{ paddingLeft: "30px" }}>
                                    <h5 style={{ padding: 0, margin: 0 }}>{localStorage.getItem('name')}</h5>
                                </div>
                                <button
                                    className="border shadow"
                                    style={{
                                        marginLeft: "30px",
                                        padding: "5px 10px",
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                        localStorage.clear()
                                        Navigate('/organizationUserlogin')
                                    }}
                                >
                                    <FiLogOut />
                                </button>
                            </div>
                        </div>

                        <div style={{ height: "84vh" }}>
                             {showComponent === "booth" ? <OrganizationUserBooth /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
