import Background from '../../../assets/images/contact.jpg'
import {useState, useEffect} from 'react'
import '../../../assets/css/candidatehome.css'

export const Banner = ({name, description}) => {
    const [search, setSearch] = useState("");

    useEffect(()=>{
  
    }, [search])

    return(
        <div id="carousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" style={{backgroundImage: `url(${Background})`}}>
                    <div className=" container text-center text-lg-start mt-1">
                        <p>{name}</p>
                    </div>
                    <div className="container text-center text-lg-start mt-5">
                        <p style={{fontSize: "20px"}}>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}