import React, { useState } from "react";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axios";
import { validateEmail } from "../../functions";
import { Popup } from "../../components/Popup";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useContext } from "react";
import { UserContext } from "../../context/AuthProvider";
import NavBar3 from "../../components/user/Navbar3";

export const AdminLogin = () => {
  const { user, setUser } = useContext(UserContext);

  const [passwordShow, setPasswordShow] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  let navigate = useNavigate();

  const HandleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setTitle("Invalid Email");
      setBody("Please enter a valid Email");
      setShowPopup(true);
    } else {
      axiosInstance
        .post("api/admin/login", { email: email, password: password })
        .then((res) => {
          if (res.data.status === "success") {
            localStorage.setItem("adminToken", res.data.token);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("adminRole", "1");
            setUser(preState => {
              return {
                ...preState,
                email: res.data.Data.email,
                token: res.data.token,
                isLoggedIn: true,
                role: "admin"
              }
            })
            navigate("/admindashboard");
          } else if (res.data.status === "failed") {
            setTitle("Ooopss..");
            setBody(res.data.message);
            setShowPopup(true);
          }
        })
        .catch((err) => console.log(err.toJSON()));
    }
  };

  return (
    <>
      <NavBar3 />
      <Popup
        show={showPopup}
        setShow={setShowPopup}
        title={title}
        body={body}
      />
      <div className="">
        <div className="item">
          <div style={{ marginTop: "8%", textAlign: "center" }}>
            <h1
              style={{ fontWeight: "600", color: "#235183", fontSize: "32px" }}
            >
              ADMIN LOGIN
            </h1>
            <form
              onSubmit={(e) => {
                HandleSubmit(e);
              }}
            >
              <div style={{ margin: "3%" }}>
                <label style={{ color: "#8f8f8f" }}>EMAIL</label>
                <br />
                <input
                  style={{
                    border: "none",
                    borderBottom: "1px solid #8f8f8f",
                    padding: "1%",
                    width: "45%",
                  }}
                  type="text"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <div style={{ margin: "3%" }}>
                <label style={{ color: "#8f8f8f" }}>PASSWORD</label>
                <br />
                <div style={{ borderBottom: "1px solid #8f8f8f", width: "46%", marginLeft: "27%", display: "flex", justifyContent: "space-between" }}>
                  <input
                    style={{
                      border: "none",
                      padding: "1%",
                      width: "100%",
                    }}
                    type={passwordShow ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <div>
                    {passwordShow ? <AiFillEyeInvisible size={20} onClick={() => setPasswordShow(!passwordShow)} /> : <AiFillEye size={20} onClick={() => setPasswordShow(!passwordShow)} />}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "2%", color: "#8f8f8f" }}>
                Forget Password?
              </div>
              <div style={{ marginTop: "3%" }}>
                <button
                  className="signIn-fill"
                  style={{
                    width: "45%",
                    padding: "1.5%",
                    backgroundColor: "#235183",
                    border: "2px solid #235183",
                    color: "white",
                    borderRadius: "2rem",
                  }}
                >
                  SIGN IN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
