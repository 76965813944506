import { MdOutlineSpaceDashboard } from "react-icons/md"

export const DashboardCard = ({textHeadline, numberHeadline}) => {
    return(
        <div className="col">
            <div className="border shadow p-3" style={{ display: "flex", borderRadius: "1rem", textTransform: "uppercase", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <MdOutlineSpaceDashboard size={40} style={{color: "#235183"}}/>
                <div style={{textAlign: "center"}}>
                    <div style={{fontSize: "26px", fontWeight: "600", color: "#235183"}}>{numberHeadline}</div>
                    <div style={{fontSize: "14px", fontWeight: "600"}}>{textHeadline}</div>
                </div>
            </div>
        </div>
    )
}