import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { EventCard } from "./EventCard";

export const OnGoingEventList = ({ setShowComponent }) => {
  let navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const getEvents = () => {
    axiosOrgAuthInstance({
      method: "post",
      url: "api/organization/listOngoingEvents",
      data: {
        limit: 4
      }
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationlogin");
        }
        setEvents(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getEvents()
  }, []);

  return (
    <div className="container my-4">
      <div>
        <h1 className="text-center" style={{ color: "#235183", fontSize: "30px", fontWeight: 600 }}>
          On Going Events
        </h1>
      </div>
      <div className="mt-2">
        <div className="row">
          {events.length > 0 ? (
            events.map((element, index) => {
              return (
                <EventCard
                  key={index}
                  name={element.name}
                  status={element.Joined}
                  description={element.description}
                  numberOfOrganizations={element.registeredOrganization.length}
                  numberOfUsers={element.registeredUser.length}
                  eventImage={element.image}
                  onGoing={true}
                  eventId={element._id}
                  getEvents={getEvents}
                />
              );
            })
          ) : (
            <div className="text-center">
              <h1 style={{ fontSize: "24px" }}>None Ongoing Events</h1>
            </div>

          )}
        </div>
      </div>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {events.length > 3 ? (
          <button
            onClick={() => setShowComponent("allongoingevents")}
            className="btn"
            style={{ backgroundColor: "#235183", color: "white" }}
          >
            See All
          </button>
        ) : null}
      </div>
    </div>
  );
};
