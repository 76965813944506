import React, { useEffect, useState } from 'react'
import { Banner } from '../../components/organization/ViewEvent/Banner'
import '../../assets/css/navbar.css'
import { Footer } from '../../components/user/Footer'
import { UserList } from '../../components/organization/ViewEvent/UserList'
import { useLocation, useNavigate } from 'react-router-dom'
import { axiosOrgAuthInstance } from '../../axios'
import { OrgNavbar } from '../../components/organization/OrgNavbar'

export const ViewEventOrg = () => {
  const navigate = useNavigate();
  const loc = useLocation();

  const [users, setusers] = useState([]);
  const eventName = loc.state.eventName;
  const eventDescription = loc.state.eventDescription;

  const getEventUsers = () => {
    let eventId = loc.state.eventId;

    axiosOrgAuthInstance({
        method: "post",
        url: "api/organization/listEventUsers",
        data: {
            eventId: eventId
        }
    }).then(res => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationlogin");
        }
        setusers(res.data.message)
    }).catch(err => console.log(err))
}

  useEffect(() => {
    getEventUsers();
  }, [])
  return (
    <div className='home'>
        <OrgNavbar/>
        <Banner name ={eventName} description={eventDescription}/>
        <UserList users = {users}/>
        <Footer/>
    </div>
  )
}