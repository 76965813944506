import { useNavigate } from "react-router-dom"

export const CompanyCard = ({company}) => {
    let navigate = useNavigate();
    return(
        <div className="col-lg-3 col-md-6 mt-3">
            <div className="border shadow" style={{borderRadius: "1rem", cursor: "pointer"}} onClick={() => {navigate("/companyprofile", {state: {orgId: company._id}})}}>
                <img className="img-fluid" style={{height: "200px", width:"100%"}} src={company?.coverImage} alt="" />
                <div style={{textAlign: "start"}} className="mx-2 mb-3">
                    <div style={{fontWeight: "bold"}} className="mt-3">
                        {company?.name}
                    </div>
                    <div className="mt-1">
                        {company.description}
                    </div>
                </div>
            </div>
        </div>
    )
}