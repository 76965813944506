import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { RegisteredEventCard } from "./RegisteredEventCard";

export const RegisteredEvents = () => {
  let navigate = useNavigate();

  const OrgId = localStorage.getItem("orgID")

  const [list, setList] = useState([])

  useEffect(()=>{
    axiosOrgAuthInstance({
      method: "post",
      url: "api/organization/listRegisteredEvents",
      data: {
        OrgId
      }
    }).then(res=> {
      if (res.data.statusCode == 403) {
        localStorage.clear();
        navigate("/organizationlogin");
      }
      setList(res.data.message)
    }).catch(err=>console.log(err))
  },[])
  return (
    <>
      <div style={{ height: "77vh" }}>
        <div
          className="row gx-0 d-flex align-items-center"
          style={{
            backgroundColor: "#235183",
            borderRadius: "1rem",
            color: "white",
          }}
        >
          <div className="col-2">
            <h3
              style={{
                padding: "10px",
                paddingBottom: 0,
                fontSize: "18px",
                marginLeft: "15px",
              }}
            >
              Event Name
            </h3>
          </div>
          <div className="col-2">
            <h3 style={{ padding: "10px", paddingBottom: 0, fontSize: "18px" }}>
              Starting Date
            </h3>
          </div>
          <div className="col-2">
            <h3 style={{ padding: "10px", paddingBottom: 0, fontSize: "18px" }}>
              Ending Date
            </h3>
          </div>
          <div className="col-2">
            <h3
              style={{
                padding: "10px",
                paddingBottom: 0,
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Total Users
            </h3>
          </div>
          <div className="col-2">
            <h3
              style={{
                padding: "10px",
                paddingBottom: 0,
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Total Organizations
            </h3>
          </div>
        </div>
        <div className="hidescroll" style={{ height: "72vh", overflow: "auto" }}>
              {list.map((element, index) => {
                return <RegisteredEventCard key={index}
                    name={element.eventName}
                    startingDate={element.eventStartDate}
                    endingDate={element.eventEndDate}
                    numberOfOrganizations={element.totalOrganizations}
                    numberOfUsers={element.totalUsers}
                    />
              })}
        </div>
      </div>
      <div className="mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item disabled">
              <Link className="page-link" to="" tabIndex="-1">
                Previous
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                1
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                ...
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                10
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="">
                Next
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
