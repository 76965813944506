import { Fragment, useRef } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../../assets/images/contact.jpg"
import { axiosOrgUserAuthInstance } from "../../../axios";

export const OrganizationUserBoothQueue = ({getActiveChats}) => {
    let navigate = useNavigate();
    const refAddToChat = useRef();

    let organizationId = localStorage.getItem("orgID");
    const [boothReq, setBoothReq] = useState([]);

    const getBoothRequest = (organizationId) => {
        axiosOrgUserAuthInstance({
            method: "post",
            url: "api/message/messengerUsersRequestOrg",
            data: {
                organizationId: organizationId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationUserlogin");
                }
                if (res.data.status == "success") {
                    setBoothReq(res.data.message.boothRequest);
                }
            })
            .catch((err) => console.log(err));
    }

    const acceptRequest = (userId) => {
  
        axiosOrgUserAuthInstance({
            method: "post",
            url: "api/organization/requestAcceptBoothU",
            data: {
                organizationId: organizationId,
                userId: userId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/organizationUserLogin");
                }
                if (res.data.status == "success") {
                    getBoothRequest(organizationId);
                    getActiveChats();
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getBoothRequest(organizationId);
    }, [])

    return (
        <div className="col-3 px-0 mx-0" style={{ border: "1px solid rgb(221, 221, 221)" }}>
            <div className="py-4 text-center border" style={{ fontSize: "20px" }}>
                QUEUE
            </div>

            <div className="hidescroll" style={{ overflow: "auto", height: "68vh" }}>

                {
                    boothReq.map((item) => {
                        return (
                            <Fragment>
                                <div className="m-0 p-0" style={{}}>
                                    <div className="d-flex justify-content-between p-2">
                                        <div className="mx-1 d-flex">
                                            <img src={item.userId.profileImage} style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                                                className="img-fluid shadow" alt="" />
                                            <div className="mt-2 mx-3 text-center">
                                                <span style={{ fontSize: "16px", color: "#235183d6" }}>{item.userId.name}</span><br />
                                                <span style={{ fontSize: "12px", textTransform: "uppercase", color: "#235183d6" }}>WEB DEVELOPER</span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button className="py-3 mx-2" ref={refAddToChat}
                                                style={{ backgroundColor: "white", border: "none", borderRadius: "1.5rem", fontSize: "12px" }} onClick={() => { 
                                                    refAddToChat.current.setAttribute("disabled", true)
                                                    acceptRequest(item.userId._id)
                                                 }}>Add to chat</button>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center">
                                    <hr className="mt-1 mb-1 w-75" />
                                </div>
                            </Fragment>
                        )
                    })
                }

            </div>


        </div>
    )
}