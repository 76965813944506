import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { axiosOrgAuthInstance } from "../../../axios"
import { DashboardCard } from "./DashboardCard"
import { EventCardList } from "./EventCardList"

export const Dashboard = ({setShowComponent, setDropDown}) => {
    let navigate = useNavigate();
    
    const [totalEvents, setTotalEvents] = useState(0)
    const [totalUsers, setTotalUsers] = useState(0)
    const [totalRegEvents, setTotalRegEvents] = useState(0)
    const [totalChatReq, setTotalChatReq] = useState(0)

    useEffect(() => {
        axiosOrgAuthInstance({
            method: "post",
            url: "/api/organization/dashboardData"
        }).then(res => {
            if (res.data.statusCode == 403) {
                localStorage.clear();
                navigate("/organizationlogin");
            }
            setTotalEvents(res.data.eventCount);
            setTotalUsers(res.data.userCount);
            setTotalRegEvents(res.data.RegisteredEventCount);
            setTotalChatReq(res.data.ChatRequestsCount);
        }).catch(err => console.log(err))
    })

    return (
        <>
            <div className="row gx-3 mx-3">
                <DashboardCard textHeadline="Total Events" numberHeadline = {totalEvents}/>
                <DashboardCard textHeadline="Total Users" numberHeadline = {totalUsers}/>
                <DashboardCard textHeadline="Total chat Requests" numberHeadline = {totalChatReq}/>
                <DashboardCard textHeadline="Registered Events" numberHeadline = {totalRegEvents}/>
            </div>
            
            <EventCardList setShowComponent={setShowComponent}/>
        </>
    )
}