import profleImage from "../../assets/images/profileDefault.png"
import banner from "../../assets/images/contact.jpg"
import "../../assets/css/style.css"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react"
import { axiosInstance } from "../../axios"
import { linkValidation, nameValidation, phoneValidation, validateEmail } from "../../functions"
import axios from "axios";
import { set } from "date-fns"
import { Popup } from "../../components/Popup"

export const OrganizationSignup = () => {
    const [pop, setPop] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    const [profileImageUp, setProfileImageUp] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        contact: "",
        description: "",
        socialLink: "",
        // socialLinks: [
        //     {
        //         title: "",
        //         link: ""
        //     },
        //     {
        //         title: "",
        //         link: ""
        //     },
        //     {
        //         title: "",
        //         link: ""
        //     }
        // ],
        coverImage: ""
    })

    let navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        const profile = document.getElementById("profileImage");

        if (!validateEmail(formData.email)) {
            // alert("invalid email")
            setBody("Invalid Email")
            setTitle("Email Failed")
            setPop(true)
        }
        else if (profile.files.length == 0){
            setBody("Profile picture not selected")
            setTitle("Profile Failed")
            setPop(true)
        }
        else if (formData.password !== formData.confirmPassword) {
            // alert("password and confirm password don't match")
            setBody("password and confirm password don't match")
            setTitle("Password Failed")
            setPop(true)
        } else if (formData.password.length <= 6) {
            // alert("password length should atleast be 7")
            setBody("password length should atleast be 7")
            setTitle("Password Failed")
            setPop(true)
        } 
        else if (!nameValidation(formData.name)) {
            
            setBody("Please Provide correct Name")
            setTitle("Name field Failed")
            setPop(true)
        }
        else if (!phoneValidation(formData.contact)) {
            
            setBody("Invalid Number, Please Provide correct Number")
            setTitle("Number Failed")
            setPop(true)
        }
        else{
            let link = formData.socialLink
            if(!linkValidation(formData.socialLink) && formData.socialLink != ""){
                link = `https://${formData.socialLink}`
            }
  
            axiosInstance({
                method: "post",
                url: "api/organization",
                data: {
                    ...formData,
                    coverImage: profileImageUp,
                    socialLink: link
                }
            }).then(res => {
                if(res.data.status === "failed"){
                    // alert("User Alreadty exist")
                    setBody("Signup Failed")
                    setTitle("Error")
                    setPop(true)
                }else if(res.data.status === "success"){
                    navigate("/organizationdashboard")
                }
            }).catch(err => console.log(err.toJSON()))
        }
    }   

    const triggerUploadProfile = () => {
        let f = document.getElementById("profileImage");
        f.click();
    }

    const UploadFile = async (e) => {
        const file = e.target.files[0]

        if (file) {
            const formData1 = new FormData()
            // formData.append("uploadFor", 'logo')
            formData1.append(
                'image',
                file,
                file.name
            )
         

            await axios.post('https://backend.tiptopcareerfair.com/imageUpload', formData1)
                .then(res => {
                    const imageUrl = res.data.message;
                   
                    setProfileImageUp(imageUrl)
                })
                .catch(err => console.log(err))
        }
    }

    return(
        <>
            <Popup show={pop} setShow={setPop} title={title} body={body} />
            <div className="grid-container">
                <div className="item hidescroll" style={{height: "100vh", overflow: "auto"}}>
                    <div style={{marginTop: "2%", textAlign: "center", height: "97vh"}}>
                        <h1 style={{fontWeight: "600", color: "#235183"}}>ORGANIZATION SIGNUP</h1>
                        <form onSubmit={ e => handleSubmit(e)}>
                            <div style={{position: "relative"}}>
                                <div style={{position: "absolute", left: "54%", top: "70%"}}>
                                    <FontAwesomeIcon className="plus-icon" style={{color: "grey"}} icon={faPlus} size="2x" onClick={() => { triggerUploadProfile() }}/>
                                    <input type="file" accept="image/png,image/jpeg,image/jpg" id="profileImage" onChange={(e) => { UploadFile(e) }} style={{ display: "none" }} />
                                </div>
                                <img src={profileImageUp !== "" ? profileImageUp : profleImage} style={{width: "160px", height: "140px", borderRadius: "50%"}} alt="" />
                            </div>
                            <div className="grid-container-1" style={{marginLeft: "5%", marginRight: "5%"}}>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>ORGANIZATION NAME *</label><br />
                                    <input 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="text" 
                                        required
                                        onChange={(e) => setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                name: e.target.value
                                            })
                                        })}    
                                    />
                                </div>
                                {/* <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f"}}>DESCRIPTION</label><br />
                                    <textarea className="hidescroll" style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", height: "100px",width: "70%", resize: "none"}}
                                        type="text" />
                                </div> */}
                            </div>
                            <div className="grid-container-2" style={{marginLeft: "5%", marginRight: "5%"}}>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>PHONE *</label><br />
                                    <input 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="text"
                                        required
                                        onChange = {(e)=> setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                contact: e.target.value
                                            })
                                        })} 
                                    />
                                </div>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>EMAIL *</label><br />
                                    <input 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="text"
                                        required
                                        onChange = {(e) => setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                email: e.target.value
                                            })
                                        })}
                                    />
                                </div>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>PASSWORD *</label><br />
                                    <input 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="password"
                                        required
                                        onChange={(e) => setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                password: e.target.value
                                            })
                                        })} 
                                    />
                                </div>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>CONFIRM PASSWORD *</label><br />
                                    <input 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="password"
                                        required
                                        onChange={(e) => setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                confirmPassword: e.target.value
                                            })
                                        })} 
                                    />
                                </div>
                            </div>

                            <div style={{margin: "3%"}}>
                                <label style={{color: "#8f8f8f", fontWeight: "600" }}>SOCIAL MEDIA LINK *</label><br />
                            </div>


                            <div className="grid-container-1" style={{marginLeft: "8%", marginRight: "8%"}}>
                                {/* <div style={{margin: "1%"}}>
                                    <input style={{border: "none", outline: "none", borderBottom: "1px solid #8f8f8f" , padding: "1%", width: "75%"}}
                                        type="text" />
                                </div> */}
                                <div style={{margin: "1%"}}>
                                    <input 
                                        style={{border: "none", outline: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "70%"}}
                                        type="text"
                                        required
                                        onChange={(e)=> setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                socialLink: e.target.value,
                                            })
                                        })} 
                                    />
                                </div>
                            </div>
                            <div className="grid-container-1" style={{marginLeft: "5%", marginRight: "5%"}}>
                                <div style={{margin: "3%"}}>
                                    <label style={{color: "#8f8f8f", fontWeight: "600" }}>DESCRIPTION</label><br />
                                    <textarea  
                                        className="hidescroll" 
                                        style={{border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", height: "100px",width: "70%", resize: "none"}}
                                        type="text"
                                        onChange={(e) => setFormData(prevStat => {
                                            return({
                                                ...prevStat,
                                                description: e.target.value
                                            })
                                        })} 
                                    />
                                </div>
                            </div>
                            <div style={{marginTop: "4%"}}>
                                <button disabled={!profileImageUp ? true : false} className="signIn-fill"
                                    style={{cursor: !profileImageUp ? "not-allowed" : "pointer", width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem"}}>SIGN
                                    UP</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="item">
                    <div className="login-cover"
                        style={{backgroundImage:
                        `linear-gradient(90deg, rgba(0, 0, 0, 0.4826681014202556) 0%, rgba(0, 0, 0, 0.7878781854538691) 100%, rgba(0, 0, 0, 1) 100%),
                        url(${banner})`, height: "100vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                        <div>
                            <h1 style={{color: "white", paddingTop: "15%"}}>ALREADY A MEMBER?</h1>
                            <p style={{color: "white", fontSize: "18px", marginTop: "2%"}}>Login</p>
                            <div style={{marginTop: "80%"}}>
                                <button onClick={() => navigate("/organizationlogin")} className="signUp-trans"
                                    style={{width: "70%", padding: "2%", border: "2px solid white", color: "white", backgroundColor: "transparent", borderRadius: "2rem"}}>Sign
                                    In</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}