import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { EventCard } from "./EventCard";

export const UpComingEventList = ({ setShowComponent }) => {
  let navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const getEvents = () => {
    axiosOrgAuthInstance({
      method: "post",
      url: "api/organization/listUpcommingEvents",
      data: {
        limit: 4
      }
    })
    .then((res) => {
      if (res.data.statusCode == 403) {
        localStorage.clear();
        navigate("/organizationlogin");
      }
      setEvents(res.data.message);
    })
    .catch((err) => console.log(err));
  }

  useEffect(() => {
    getEvents()
  }, []);

  return (
    <div className="container my-4">
      <div>
        <h1 className="text-center" style={{ color: "#235183", fontSize: "30px", fontWeight: 600 }}>
          Up Coming Events
        </h1>
      </div>
      <div className="mt-2">
        <div className="row">
          {events.map((element, index) => {
            return (
              <EventCard
                key={index}
                name={element.name}
                status={element.Joined}
                description={element.description}
                numberOfOrganizations={element.registeredOrganization.length}
                numberOfUsers={element.registeredUser.length}
                eventId={element._id}
                onGoing={false}
                eventImage={element.image}
                getEvents={getEvents}
              />
            );
          })}
        </div>
      </div>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {events.length > 3 ? (
          <button
            onClick={() => setShowComponent("allupcomingevents")}
            className="btn"
            style={{ backgroundColor: "#235183", color: "white" }}
          >
            See All
          </button>
        ) : null}
      </div>
    </div>
  );
};
