import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { EventCard } from "./EventCard";

export const AllOnGoingEvents = ({ setShowComponent }) => {
  const eventsPerPage = 4
  let navigate = useNavigate();
  
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  const getEvents = () => {
    axiosOrgAuthInstance({
      method: "post", 
      url: "api/organization/listOngoingEvents",
      data: {
        currentPage,
      }
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationlogin");
        }
        setEvents(res.data.message);
        setTotalEvents(res.data.eventCount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getEvents()
  }, [currentPage]);

  const paginationArray = []
  let startIndex = 0
  if(Math.ceil(totalEvents / eventsPerPage) == 2 || Math.ceil(totalEvents / eventsPerPage) == 1){}
  else if(Math.ceil(totalEvents / eventsPerPage) == 3){
    paginationArray.push(1);
  }
  else if(currentPage == Math.ceil(totalEvents / eventsPerPage) - 1){
    paginationArray.push(Math.ceil(totalEvents / eventsPerPage) -3);
    paginationArray.push(Math.ceil(totalEvents / eventsPerPage) -2);
  }else{
    for (let i = currentPage-1; i < Math.ceil(totalEvents / eventsPerPage); i++) {
      if(startIndex >= 2) break; 
      if(i < 1) continue;
      paginationArray.push(i);
      startIndex++
    }
  }

  return (
    <div className="container my-4">
            <div
        className="mt-4"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          // position: "absolute",
        }}
      >
        <button
          onClick={() => setShowComponent("dashboard")}
          className="btn"
          style={{
            backgroundColor: "#235183",
            color: "white",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
          }}
        >
          <IoArrowBack size={30} />
        </button>
      </div>
      <div>
        <h1 className="text-center" style={{ color: "#235183" }}>
          On Going Events
        </h1>
      </div>
      <div className="mt-2">
        <div className="row">
          {events.length > 0 ? (
            events.map((element, index) => {
              
              return (
                <EventCard
                  key={index}
                  name={element.name}
                  status={element.Joined}
                  description={element.description}
                  numberOfOrganizations={element.registeredOrganization.length}
                  numberOfUsers={element.registeredUser.length}
                  onGoing={true}
                  eventImage={element.image}
                  getEvents={getEvents}
                />
              );
            })
          ) : (
            <h1>None Ongoing</h1>
          )}
        </div>
      </div>
      <div className="mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className={currentPage < 1 ? "page-item disabled" : "page-item"}>
              <button className="page-link" to="" onClick={()=>{
                setCurrentPage(prevStat => {
                  if(prevStat>=1){
                    return prevStat-1
                  }
                  return prevStat
                })
              }}>
                Previous
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" to="" onClick={()=> setCurrentPage(0)} style={currentPage == 0 ? {background: '#235183', color:'white'} : {}}>
                1
              </button>
            </li>
            {
              Math.ceil(totalEvents / eventsPerPage) > 4 && currentPage >= 3 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat - 2)}>
                  ...
                </button>
              </li>: null
            }
            {
              paginationArray.map((item, index) => {
                return <li key={index} className="page-item">
                  <button className="page-link" to="" onClick={()=> setCurrentPage(item)} style={currentPage == item ? {background: '#235183', color:'white'} : {}}>
                    {item + 1}
                  </button>
                </li>
              })
            }
            {
              Math.ceil(totalEvents / eventsPerPage) > 4 && currentPage < Math.ceil(totalEvents / eventsPerPage) - 2 ? <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(prevStat => prevStat + 2)}>
                  ...
                </button>
              </li> : null
            }
            {
              Math.ceil(totalEvents / eventsPerPage) == 1 ? null : <li className="page-item">
                <button className="page-link" to="" onClick={()=> setCurrentPage(Math.ceil(totalEvents / eventsPerPage) - 1)} style={currentPage == Math.ceil(totalEvents / eventsPerPage) - 1 ? {background: '#235183', color:'white'} : {}}>
                  {Math.ceil(totalEvents / eventsPerPage)}
                </button>
              </li> 
            }
            <li className={currentPage >= Math.ceil(totalEvents / eventsPerPage) - 1 ? "page-item disabled" : "page-item"}>
              <button className="page-link" to="" onClick={() => {
                  setCurrentPage(prevStat => {
                    return prevStat+1
                  })
              }}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
