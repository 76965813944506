import { useEffect, useState } from "react";
import { axiosOrgAuthInstance } from "../../../axios";
import { useNavigate } from "react-router-dom";

export const BoothChat = ({ userId, getActiveChats, getUserChat, chat }) => {
  let navigate = useNavigate();
  const [text, setText] = useState("");

  const sendMessage = (messageId) => {
    axiosOrgAuthInstance({
      method: "post",
      url: "api/message/sendMessageUpdated",
      data: {
        messageId: messageId,
        eventId: "638e7ab1f0565a29af092400",
        sender: "organization",
        message: text,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationlogin");
        }
        if (res.data.status == "success") {
          getUserChat();
          getActiveChats();
          setText("");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userId !== "") {
      getUserChat();
    }
  }, [userId]);

  return (
    <div
      className="col-6 m-0 p-0"
      style={{ border: "1px solid rgb(221, 221, 221)", position: "relative" }}
    >
      {userId !== "" ? (
        <>
          <div className="m-0 p-0" style={{ backgroundColor: "#235183d6" }}>
            <div className="d-flex justify-content-between p-2">
              <div className="mx-1 d-flex">
                <img
                  src={chat?.userId?.profileImage}
                  style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                  className="img-fluid shadow"
                  alt=""
                />
                <div className="mt-2 mx-3 text-center">
                  <span style={{ fontSize: "16px", color: "white" }}>
                    {chat?.userId?.name}
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      color: "white",
                    }}
                  >
                    Web Developer
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <button
                  className="p-3"
                  onClick={() => {navigate("/user-org-Profile", {state: {user: userId}})}}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "1.5rem",
                    fontSize: "12px",
                  }}
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="m-0 p-0" style={{ background: "#ffffff" }}>
            <div
              className="d-flex justify-content-between p-2"
              style={{ height: "80px" }}
            ></div>
          </div>
        </>
      )}

      <div
        className="mt-4 mx-3 hidescroll"
        style={{ height: "56vh", overflow: "auto" }}
      >
        {chat?.message?.map((item) => {
          return item.sender == "organization" ? (
            <>
              <div className="d-flex justify-content-end">
                <div
                  className="my-1"
                  style={{
                    color: "#466D97",
                    width: "max-content",
                    padding: ".8rem 1rem .8rem 1rem",
                    borderRadius: "2rem",
                    fontSize: "14px",
                    border: "1px solid rgb(221, 221, 221)",
                  }}
                >
                  {item.message}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-start">
                <div
                  className="text-white my-1"
                  style={{
                    backgroundColor: "#466D97",
                    width: "max-content",
                    padding: ".8rem 1rem .8rem 1rem",
                    borderRadius: "2rem",
                    fontSize: "14px",
                  }}
                >
                  {item.message}
                </div>
              </div>
            </>
          );
        })}
      </div>

      {userId !== "" ? (
        <>
          <div
            className="w-100"
            style={{ position: "absolute", bottom: "0", left: "0" }}
          >
            <hr className="mb-2 pb-0" />
            <div className=" d-flex mb-2 mx-2">
              <input
                type="text"
                style={{ width: "100%" }}
                className="form-control"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <button
                className="mx-2"
                style={{
                  padding: "10px 20px 10px 20px",
                  backgroundColor: "#235183d6",
                  color: "white",
                  border: "none",
                  borderRadius: ".5rem",
                }}
                onClick={() => {
                  sendMessage(chat?._id);
                }}
              >
                Send
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
