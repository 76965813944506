import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { Popup } from "../../Popup";

export const OrganizationDetails = ({ setShowComponent }) => {
  const [pop, setPop] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const [company, setCompany] = useState();

  const getDetails = () => {
    axiosOrgAuthInstance({
        method: "post",
        url: "/api/organization/orgDetails"
    }).then(res => {
        if(res.data.status == "success") setCompany(res.data.message);
        else {
            setTitle("not found")
            setBody("no organization was found")
            setPop(true)
        }
    }).catch(err => {
        console.log(err.message)
    })
  }

  useEffect(() => {
    getDetails()
  }, []);

  return (
    <>
      <Popup show={pop} setShow={setPop} title={title} body={body} />
      <div className="container">
        <div className="row mt-5" style={{ backgroundColor: "#F8F9FA" }}>
          <div className="col-12 col-lg-6">
            <img
              src={company?.coverImage}
              className="img-fluid"
              style={{ width: "100vh" }}
              alt=""
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="mt-5">
              <div
                className="fw-bold my-1"
                style={{
                  fontSize: "32px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {company?.name}
              </div>
              <div className="my-1" style={{ fontWeight: "600" }}>
                Email
                <span
                  className="mx-2"
                  style={{ fontWeight: "400", color: "#235183" }}
                >
                  {company?.email}
                </span>
              </div>
              <div className="my-1" style={{ fontWeight: "600" }}>
                Phone No
                <span
                  className="mx-2"
                  style={{ fontWeight: "400", color: "#235183" }}
                >
                  {company?.contact}
                </span>
              </div>
              <div className="mt-2">
                <div style={{ fontWeight: "600" }}>Company Description</div>
                {company?.description}
              </div>
              <div className="mt-4 text-end d-flex flex-column flex-md-row">

                <button
                  className="btn btn-primary mx-1 my-1"
                  style={{ backgroundColor: "#29629F" }}
                  onClick={() => {setShowComponent("editProfile")}}
                >
                  Edit profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
