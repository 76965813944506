import { Footer } from "../../components/user/Footer";
import { axiosAuthInstance } from "../../axios";
import { UserMessagesLeft } from "../../components/user/userMessages/UserMessagesLeft";
import { UserMessagesRight } from "../../components/user/userMessages/UserMessagesRight";
import { useState } from "react";
import { useEffect } from "react";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/user/NavBar";

const socket = io("https://backend.tiptopcareerfair.com");

export const UserMessages = () => {
  let navigate = useNavigate();
  const userId = localStorage.getItem("userID");
  const [activeChat, setActiveChat] = useState();
  const [organizationId, setOrganizationId] = useState("");
  const [chat, setChat] = useState(null);

  socket.on(userId, (data) => {
    if (data.sender == "organization") {
      if (data.organizationId == organizationId) {
        getActiveChats();
        getUserChat();
      } else {
        getActiveChats();
      }
    }
  });

  useEffect(() => {}, [userId]);

  const getActiveChats = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/message/messengerActiveOrgs",
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        if (res.data.status == "success") {
          setActiveChat(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUserChat = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/message/getMessage",
      data: {
        userId: userId,
        organizationId: organizationId,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/");
        }
        if (res.data.status == "success") {
          setChat(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getActiveChats();
  }, []);

  return (
    <>
      <NavBar />
      <div class="container-fluid mb-5">
        <div
          className="text-center my-3"
          style={{ fontSize: "32px", fontWeight: "600", color: "#235183" }}
        >
          MESSAGES
        </div>

        <div className="row mx-5 shadow" style={{ height: "80vh" }}>
          <UserMessagesLeft
            activeChat={activeChat}
            setOrganizationId={setOrganizationId}
            getActiveChats={getActiveChats}
          />
          <UserMessagesRight
            organizationId={organizationId}
            getActiveChats={getActiveChats}
            getUserChat={getUserChat}
            chat={chat}
            setChat={setChat}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
