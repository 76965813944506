import Background from "../../../assets/images/contact.jpg";
import { useState, useEffect } from "react";
import "../../../assets/css/candidatehome.css";

export const Banner = ({ search, setSearch, companyList, home}) => {
  const [searchList, setSearchList] = useState([]);
  useEffect(() => {
    setSearchList([...companyList]);
  }, [companyList]);
 

  useEffect(() => {

  }, [search]);

  return (
    <div id="carousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div
          className="carousel-item active"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="container text-center text-lg-start mt-5">
            {home ? <h1 style={{textAlign: 'center', fontSize: '60px'}}>Home</h1> : null}
            <h2>Search for</h2>
            <p>ORGANIZATIONS</p>
            <div className="mt-4">
              <input
                className="p-2"
                style={{ width: "325px" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search for organizations"
              />
              <button
                className="p-2 shadow mx-1"
                style={{
                  backgroundColor: "#235183",
                  border: "2px solid #235183",
                  color: "white",
                }}
              >
                Search
              </button>
              <div
                className="hidescroll p-2 organizationsearch"
                style={
                  search === ""
                    ? { display: "none" }
                    : {
                        borderRadius: "0 0 0.5rem 0.5rem",
                        background: "white",
                        width: "324px",
                        overflow: "auto",
                        position: "absolute",
                      }
                }
              >
                {/* ///Changes */}
                {searchList?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0 0 3px 0",
                        margin: "0 4px 4px 4px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <img
                        src={value.coverImage}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "2rem",
                        }}
                      />
                      <h3
                        style={{
                          padding: 0,
                          margin: 0,
                          marginLeft: "10px",
                          fontSize: "17px",
                          color: "grey",
                        }}
                      >
                        {value.name}
                      </h3>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
