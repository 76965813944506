import { MdDelete, MdOutlineDownloadDone } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosAdminAuthInstance } from '../../../../axios'

export const OrgReqCard = ({ organization, event, getRequests, setPopUpUser, setIsOpen, number }) => {
    const [modalOpen, setModalOpen] = useState(false)
    let navigate = useNavigate();

    const acceptOrg = () => {
        axiosAdminAuthInstance({
            method: "post",
            url: "api/admin/acceptOrgainzationEventRequest",
            data: {
                eventId: organization._id,
                organizationId: organization.organizationId
            }
        }).then(res => {
            if(res.data.statusCode == 403){
                localStorage.clear();
                navigate("/adminlogin")
              }
            getRequests()
        }).catch(err => console.log(err))
    }
    const rejectOrg = () => {
        axiosAdminAuthInstance({
            method: "post",
            url: "api/admin/rejectOrganizationEventRequest",
            data: {
                eventId: organization._id,
                organizationId: organization.organizationId
            }
        }).then(res => {
            if(res.data.statusCode == 403){
                localStorage.clear();
                navigate("/adminlogin")
              }
            getRequests()
        }).catch(err => console.log(err))
    }

    return (
        <>
            <div className="row gx-0 my-2 d-flex align-items-center" style={{ height: "60px", borderRadius: "1rem", backgroundColor: "#cfcfcf", color: "#235183" }}>
                <div className="col-3" style={{ overflow: "hidden" }}>
                    <h6 style={{ padding: "8px", marginLeft: "15px" }}>{organization.organizationName}</h6>
                </div>
                <div className="col-3">
                    <h6 style={{ padding: "8px" }}>{organization.email}</h6>
                </div>
                <div className="col-3">
                    <h6 style={{ padding: "8px" }}>{event}</h6>
                </div>
                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => acceptOrg()}>
                        <MdOutlineDownloadDone size={30} color="green" />
                    </h6>
                </div>
                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => rejectOrg()}><MdDelete size={30} color="red" /></h6>
                </div>
                <div className="col-1">
                    <h6 style={{ padding: "8px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => { setPopUpUser(organization); setIsOpen(true) }}> <AiFillEye size={30} /></h6>
                </div>
            </div>
        </>
    )
}
