import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosOrgUserAuthInstance } from "../../../axios";

export const OrganizationUserBoothChatRoom = ({ setUserId, getActiveChats, activeChats }) => {
  let navigate = useNavigate();
  
  let organizationId = localStorage.getItem("orgID");

  const endChat = (userId) => {
    axiosOrgUserAuthInstance({
      method: "post",
      url: "api/organization/endChat",
      data: {
        organizationId: organizationId,
        userId: userId,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationUserlogin");
        }
        if (res.data.status == "success") {
          setUserId("");
          getActiveChats();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getActiveChats();
  }, []);

  return (
    <div
      className="col-3 px-0 mx-0"
      style={{ border: "1px solid rgb(221, 221, 221)" }}
    >
      <div className="py-4 text-center border" style={{ fontSize: "20px" }}>
        CHAT ROOM
      </div>

      <div className="hidescroll" style={{ overflow: "auto", height: "68vh" }}>
        {activeChats.map((item) => {
          return (
            <Fragment>
              <div
                className="m-0 p-0"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserId(item.userId._id);
                }}
              >
                <div className="d-flex justify-content-between p-2">
                  <div className="mx-1 d-flex">
                    <img
                      src={item?.userId?.profileImage}
                      style={{
                        height: "65px",
                        width: "65px",
                        borderRadius: "50%",
                        marginTop: "10px",
                      }}
                      className="img-fluid shadow"
                      alt=""
                    />
                    <div className="mt-2 mx-3 text-center">
                      <span style={{ fontSize: "16px", color: "#235183d6" }}>
                        {item?.userId?.name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          textTransform: "uppercase",
                          color: "#235183d6",
                        }}
                      >
                        {item?.message[0]?.message}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "10px",
                          textTransform: "uppercase",
                          color: "red",
                        }}
                        onClick={() => {
                          endChat(item?.userId?._id);
                        }}
                      >
                        End Chat
                      </span>
                    </div>
                  </div>
                  <div>
                    {/* <i className="fa-solid fa-circle-dot" style="color: #235183;"></i> */}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <hr className="mt-1 mb-1 w-75" />
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
