import { useEffect, useState } from "react";
import { axiosOrgUserAuthInstance } from "../../../axios";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { OrganizationUserBoothChatRoom } from "./OrganizationUserBoothChatRoom";
import { OrganizationUserBoothChat } from "./OrganizationUserBoothChat";
import { OrganizationUserBoothQueue } from "./OrganizationUserBoothQueue";

const socket1 = io("https://backend.tiptopcareerfair.com");

export const OrganizationUserBooth = () => {
  let navigate = useNavigate();

  let organizationId = localStorage.getItem("orgID");
  let organizationUserId = localStorage.getItem("organizationUserId");
  const [userId, setUserId] = useState("");
  const [activeChats, setActiveChats] = useState([]);
  const [chat, setChat] = useState(null);

  socket1.on(organizationUserId, (data) => {
    console.log(data,"===========================================")
    if (data.sender == "user") {
      if (data.userId == userId) {
        getActiveChats();
        getUserChat();
      } else {
        getActiveChats();
      }
    }
  });

  useEffect(() => {}, [organizationUserId]);

  const getActiveChats = () => {
    axiosOrgUserAuthInstance({
      method: "post",
      url: "api/message/messengerActiveUsersOrgU",
      data: {
        organizationId: organizationId,
        orgUserId: organizationUserId
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationUserlogin");
      }
        if (res.data.status == "success") {
          setActiveChats(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUserChat = () => {
    axiosOrgUserAuthInstance({
      method: "post",
      url: "api/message/getMessageU",
      data: {
        userId: userId,
        organizationId: organizationId,
        organizationUserId: organizationUserId
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationUserlogin");
        }
        if (res.data.status == "success") {
          setChat(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div class="container-fluid">
      <div class="row mx-0 shadow" style={{ height: "80vh" }}>
        <OrganizationUserBoothChatRoom
          setUserId={setUserId}
          getActiveChats={getActiveChats}
          activeChats={activeChats}
        />
        <OrganizationUserBoothChat
          userId={userId}
          getActiveChats={getActiveChats}
          getUserChat={getUserChat}
          chat={chat}
        />
        <OrganizationUserBoothQueue getActiveChats={getActiveChats} />
      </div>
    </div>
  );
};
