import React, { useState } from "react";
import "../../assets/css/style.css"
import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/contact.jpg"
import { axiosInstance } from "../../axios";
import { validateEmail } from "../../functions"
import { Popup } from "../../components/Popup";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useContext } from "react";
import { UserContext } from "../../context/AuthProvider";
import NavBar3 from "../../components/user/Navbar3";

export const OrganizationUserLogin = () => {

    const { user, setUser } = useContext(UserContext);

    let navigate = useNavigate();
    const [passwordShow, setPasswordShow] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pop, setPop] = useState(false);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")


    const HandleSubmit = (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            // alert("invalid Email")
            setTitle("Invalid Email")
            setBody("Please enter a valid Email")
            setPop(true)
        } else {
            axiosInstance.post("api/organization-user/signIn", { email: email, password: password }).then(res => {
                console.log(res.data)
                if (res.data.status === "success") {
                    console.log("result")
                    localStorage.setItem('orgUserToken', res.data.token)
                    localStorage.setItem('orgID', res.data.Data.organizationId)
                    localStorage.setItem('isLoggedIn', true)
                    localStorage.setItem('name', res.data.Data.name)
                    localStorage.setItem('organizationUserId', res.data.Data._id)
                    localStorage.setItem('orgRole', "1")
                    setUser(preState => {
                        return {
                            ...preState,
                            email: res.data.Data.email,
                            token: res.data.token,
                            userId: res.data.Data._id,
                            isLoggedIn: true,
                            role: "org"
                        }
                    })
                    window.location.href = '/organizationUserdashboard';
                    // window.location("");
                } else if (res.data.status === "failed") {
                    setTitle("Login failed")
                    setBody(res.data.message)
                    setPop(true)
                }
            })
                .catch(err => console.log(err.toJSON()))
        }
    }

    return (
        <>
            <NavBar3 />
            <Popup show={pop} setShow={setPop} title={title} body={body} />
            <div className="grid-container">
                <div className="item">
                    <div style={{ marginTop: "15%", textAlign: "center" }}>
                        <h1 style={{ fontWeight: "600", color: "#235183", fontSize: '32px' }}>ORGANIZATION USER LOGIN</h1>
                        <form onSubmit={(e) => { HandleSubmit(e) }}>
                            <div style={{ margin: "3%" }}>
                                <label style={{ color: "#8f8f8f" }}>EMAIL</label><br />
                                <input style={{ border: "none", borderBottom: "1px solid #8f8f8f", padding: "1%", width: "45%" }}
                                    type="text" value={email} onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            <div style={{ margin: "3%" }}>
                                <label style={{ color: "#8f8f8f" }}>PASSWORD</label><br />
                                <div style={{ borderBottom: "1px solid #8f8f8f", width: "46%", marginLeft: "27%", display: "flex", justifyContent: "space-between" }}>
                                    <input style={{ border: "none", padding: "1%", width: "100%" }}
                                        type={passwordShow ? "text" : "password"} value={password} onChange={(event) => setPassword(event.target.value)} />
                                    <div>
                                        {passwordShow ? <AiFillEyeInvisible size={20} onClick={() => setPasswordShow(!passwordShow)} /> : <AiFillEye size={20} onClick={() => setPasswordShow(!passwordShow)} />}
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: "2%", color: "#8f8f8f" }}>
                                Forget Password?
                            </div>
                            <div style={{ marginTop: "3%" }}>
                                <button
                                    className="signIn-fill"
                                    style={{ width: "45%", padding: "1.5%", backgroundColor: "#235183", border: "2px solid #235183", color: "white", borderRadius: "2rem" }}>SIGN
                                    IN</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="item">
                    <div className="login-cover"
                        style={{
                            backgroundImage:
                                `linear-gradient(90deg, rgba(0, 0, 0, 0.4826681014202556) 0%, rgba(0, 0, 0, 0.7878781854538691) 100%, rgba(0, 0, 0, 1) 100%),url(${banner})`, height: "100vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"
                        }}>

                    </div>
                </div>
            </div>
        </>
    )

}