import { useNavigate } from "react-router-dom"
  

export const UserCard = ({details}) => {
    console.log(details)
    let navigate = useNavigate();
    return(
        <div className="col-lg-3 col-md-6 mt-3">
            <div className="border" style={{borderRadius: "1rem", cursor: "pointer"}} onClick={() => {navigate("/user-org-Profile", {state: {user: details._id}})}}>
                <img className="img-fluid" src={details?.profileImage} style={{width:"100%", height: "200px"}} alt="" />
                <div style={{textAlign: "start"}} className="mx-2 mb-3">
                    <div style={{fontWeight: "bold"}} className="mt-3">
                        {details?.name}
                    </div>
                    <div className="mt-1">
                        {details && details.description ? details.description : "no description provided by the user"}
                    </div>
                </div>
            </div>
        </div>
    )
}