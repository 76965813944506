import loadingImg from "../assets/images/loading.gif";
import NavBar3 from "../components/user/Navbar3";
import styles from "../pages/Home.module.css";
const Home = () => {
    return (
        <>
            <NavBar3 />
            <div className={`${styles.constructionContainer} mt-5`}>
                <h1 className={styles.header}>Page under construction</h1>
                <p className={styles.message}>
                    We are working hard to bring this page. Stay tuned
                </p>
                <div className={styles.imageContainer}>
                    <img
                        src={loadingImg}
                        alt="construction"
                        className={styles.ConstructionImage}
                    />
                </div>
            </div>

        </>
    )
}

export default Home
