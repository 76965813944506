import { createContext, useState } from "react";

export const UserContext = createContext({
    isLoggedIn: false,
    email:"",
    token:"",
    userId:"",
    role:"",
    isLoading:""
})

export const AuthProvider = ({children}) => {
    const [user,setUser] = useState({
        isLoggedIn: false,
        email:"",
        token:"",
        userId:"",
        role:"",
        isLoading:""
})
    return(
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}