import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../../axios";
import { UserContext } from "../../../context/AuthProvider";

export const UserMessagesLeft = ({ activeChat, setOrganizationId, getActiveChats }) => {
    let navigate = useNavigate();
    const {user} = useContext(UserContext);

    const endChat = (organizationId) => {
        axiosAuthInstance({
            method: "post",
            url: "api/organization/endChat",
            data: {
                organizationId: organizationId,
                userId: user.userId
            }
        })
            .then((res) => {
                if (res.data.statusCode == 403) {
                    localStorage.clear();
                    navigate("/");
                }
                if (res.data.status == "success") {
                    setOrganizationId("")
                    getActiveChats();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="col-4 px-0 mx-0" style={{ border: "1px solid rgb(221, 221, 221)" }}>
            <div className="text-center border" style={{ fontSize: "20px", padding: "20px 0px" }}>
                MESSAGES
            </div>

            <div>
                {activeChat?.map((item) => {
                    return (
                        <>
                            <div className="m-0 p-0" style={{ cursor: "pointer" }} onClick={() => { setOrganizationId(item.organizationId._id) }}>
                                <div className="d-flex justify-content-between p-2">

                                    <div className="mx-1 d-flex justify-content-between w-100">
                                        <img src={item?.organizationId?.coverImage} style={{ height: "65px", width: "65px", borderRadius: "50%" }}
                                            className="img-fluid shadow" alt="" />
                                        <div className="mt-2 mx-3 text-center">
                                            <span style={{ fontSize: "20px", fontWeight: "600", color: "#235183d6" }}>{item?.organizationId?.name}</span><br />
                                            <span
                                                style={{ fontSize: "12px", textTransform: "uppercase", color: "#235183d6" }}>{item?.message[0]?.message}
                                            </span>
                                        </div>
                                        <button style={{ outline: "2px solid black", width: "50px", backgroundColor: "transparent", border: "none", borderRadius: "1rem" }} onClick={() => { endChat(item?.organizationId?._id) }}>End<br />Chat</button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <hr className="mt-1 mb-1 w-100" />
                            </div>
                        </>
                    )

                })}

            </div>
        </div>
    )
}