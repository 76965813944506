import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosOrgAuthInstance } from "../../../axios";
import { Popup } from "../../Popup";

export const EventCard = ({
  onGoing,
  name,
  description,
  numberOfUsers,
  numberOfOrganizations,
  status,
  eventId,
  getEvents,
  eventImage
}) => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleRegisteration = () => {
    axiosOrgAuthInstance({
      method: "post",
      url: "api/organization/requestEventJoin",
      data: {
        organizationId: localStorage.getItem("orgID"),
        eventId,
      },
    })
      .then((res) => {
        if (res.data.statusCode == 403) {
          localStorage.clear();
          navigate("/organizationlogin");
        }
        getEvents()
        setShow(true)
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Popup show={show} setShow={setShow} title="Succesful" body="Your request was submitted and is pending approval."/>
      <div className="col-lg-3 col-md-6 mt-3">
        <div
          className="border shadow"
          style={{ borderRadius: "1rem" }}
          onClick={() => {}}
        >
          <img className="img-fluid" style={{height: "200px", width:"100%"}} src={eventImage} alt="" />
          <div style={{}} className="mx-2 mb-3">
            <div style={{ fontWeight: "bold" }} className="mt-3 text-center">
              {name}
            </div>
            <div className="mt-1 text-center">
              {description}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "stretch" }}>
            <div
              style={{
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                borderRadius: "0 0 0 1rem",
                backgroundColor: "#235183",
                color: "white",
                width: "30%",
              }}
            >
              <span>{numberOfUsers}</span>
              <span>Users</span>
            </div>
            <div
              style={{
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60px",
                borderRadius: "0 0 0 0",
                backgroundColor: "#235183",
                color: "white",
                width: "30%",
              }}
            >
              <span>{numberOfOrganizations}</span>
              <span>Orgs</span>
            </div>
            <button
              onClick={() => {
                if (status == 0) {
                  handleRegisteration();
                }
                else if (status == 1 && onGoing) {
                  navigate("/eventOrg", {
                    state: {
                      eventId: eventId,
                      eventName: name,
                      eventDescription: description
                    }
                  });
                } 
              }}
              style={{
                border: "2px solid #235183",
                height: "60px",
                borderRadius: "0 0 1rem 0",
                backgroundColor: "white",
                color: `${
                  status == 0
                    ? "#235183"
                    : status == 1
                    ? "green"
                    : status == 2
                    ? "grey"
                    : null
                }`,
                fontWeight: "600",
                width: "40%",
              }}
            >
              {status == 0
                ? "Register"
                : status == 1
                ? onGoing ? "View" : "Approved"
                : status == 2
                ? "Approval Pending"
                : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
