import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../axios";
import logo from "../../assets/images/logo.png";

const NavBar = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    profileImage: "",
  });

  // Fetch user profile details
  const getUserProfile = () => {
    axiosAuthInstance({
      method: "post",
      url: "api/user/userDetails",
    })
      .then((res) => {
        if (res.data.statusCode === 403) {
          localStorage.clear();
          navigate("/");
        }
        if (res.data.status === "success") {
          setUserData({
            name: res.data.message.name,
            profileImage: res.data.message.profileImage,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  // Log out functionality
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "#235183" }}
    >
      <div className="container-fluid">
        {/* Logo */}
        <Link className="navbar-brand mx-5" to="/">
          <img src={logo} alt="Logo" style={{ width: "100px" }} />
        </Link>

        {/* Navbar Toggler for Mobile View */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Items */}
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-end w-100 mx-5">
            {/* Home Link */}
            <li className="nav-item">
              <Link
                to="/candidatehome"
                className="nav-link"
                aria-current="page"
              >
                Home
              </Link>
            </li>

            {/* Chat History Link */}
            <li className="nav-item">
              <Link to="/userchathistory" className="nav-link">
                Chat History
              </Link>
            </li>

            {/* Messages Link */}
            <li className="nav-item">
              <Link to="/usermessages" className="nav-link">
                Messages
              </Link>
            </li>

            {/* User Dropdown */}
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {userData?.name || "User"}
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/userprofile">
                    <div className="d-flex">
                      <img
                        src={userData?.profileImage}
                        alt="Profile"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                      <div className="mx-2 mt-3">
                        <span style={{ fontSize: "16px" }}>Profile</span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    <div className="mt-2 mx-2">
                      <span
                        className="mx-2"
                        style={{ fontSize: "16px" }}
                        onClick={logOut}
                      >
                        Log Out
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
